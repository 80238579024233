<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0 mt-1"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by email"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          v-if="advancedFilter.hasOwnProperty('country')"
          size="sm"
        >
          <label>Country</label>
          <country-select
            v-model="advancedFilter.country"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <data-table
      :module-name="'accountLinkageSummaryList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="true"
    >
      <template #cell(search_email)="data">
        <b-link
          :to="{ name: 'user-detail', params: { id: data.item.search_id } }"
        >
          {{ data.item.search_email }} ({{ data.item.search_country }})
        </b-link>
      </template>

      <template #cell(target_email)="data">
        <b-link
          :to="{ name: 'user-detail', params: { id: data.item.target_id } }"
        >
          {{ data.item.target_email }}  ({{ data.item.target_country }})
        </b-link>
      </template>

      <template #cell(linkages_count)="data">
        <b-badge
          variant="light-warning"
          size="md"
          class="m-1"
        >
          {{ data.item.linkages_count }}
        </b-badge>
        <b-button
          variant="outline-primary"
          @click="showLinkage(data.item)"
        >
          Show linkages
        </b-button>
      </template>
    </data-table>
    <b-modal
      id="linkageModal"
      title="Users connections on another accounts"
      :no-close-on-backdrop="true"
      size="xl"
      hide-footer
    >
      <account-linkage-table-gql :columns="linkageColumns" />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BFormInput, BFormGroup, BLink, BBadge, BModal,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import DataTable from '@/components/ui/tables/DataTable.vue'
import table from '@/mixins/table'
import { formatDateTime, formatDate } from '@/plugins/formaters'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import AccountLinkageTableGql from '@/components/entity/accountLinkage/AccountLinkageTableGql.vue'

export default {
  components: {
    AccountLinkageTableGql,
    CountrySelect,
    BModal,
    DataTable,
    BBadge,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BLink,
  },
  mixins: [table],
  props: {
    advancedFilter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'search_email',
          sortable: false,
        },
        {
          key: 'target_email',
          sortable: false,
        },
        {
          key: 'linkages_count',
          sortable: false,
        },
      ],
      filterOn: ['email'],
      filter: null,
      defaultFilter: { search: '', user_id: null, country: null },
      search: '',

      searchId: null,
      targetId: null,
    }
  },
  computed: {
    ...get('accountLinkageSummaryList', ['data']),
    linkageColumns() {
      return [
        {
          name: 'customQuickSearch', label: 'Quick search', filterable: 'like', visible: false,
        },
        {
          name: 'searchUser',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.searchId,
          visible: true,
        },
        {
          name: 'account', label: 'Account',
        },
        {
          name: 'targetUser',
          label: 'Target user',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.targetId,
          visible: true,
        },
        {
          name: 'linked_account', label: 'Linked account',
        },
        {
          name: 'createdAt', label: 'Created at',
        },
      ]
    },
  },
  beforeMount() {
    if (!this.advancedFilter) {
      this.advancedFilter = { ...this.defaultFilter }
    }
  },
  methods: {
    formatDateTime,
    formatDate,
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
    showLinkage(item) {
      this.searchId = item.search_id
      this.targetId = item.target_id

      this.$bvModal.show('linkageModal')
    },
  },
}
</script>
