<template>
  <div>
    <b-card v-if="showActionBar">
      <div class="d-flex flex-wrap">
        <b-button-toolbar>
          <b-button-group>
            <b-button
              variant="primary"
              @click.prevent="onCreateWithdrawal"
            >
              Create withdrawal
            </b-button>
          </b-button-group>
        </b-button-toolbar>

        <b-badge
          v-for="(item, key) in withdrawalCounts"
          :key="key"
          :variant="`light-${withdrawalStateVariants[item.state]}`"
          class="m-50"
        >
          {{ item.state }}: {{ item.withdrawals_count }} | {{ formatCurrency(item.withdrawals_sum, 'USD') }}
        </b-badge>
      </div>
    </b-card>
    <gql-table
      ref="withdrawalTable"
      :query="query"
      :fields="columns"
      :title="title"
      query-type="withdrawals"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      :filter-name="filterName"
      @edit="onEdit"
      @confirm="onConfirm"
      @removeCertificate="onRemoveCertificate"
      @stateInvestigationContacted="onInvestigationContacted"
      @stateInvestigationDeclined="onInvestigationDeclined"
      @editComment="onEditComment"
      @downloadCertificate="onDownloadCertificate"
      @downloadFundedCertificate="onDownloadFundedCertificate"
      @downloadSocialCertificate="onDownloadSocialCertificate"
      @downloadTotalPayoutCertificate="onDownloadTotalPayoutCertificate"
      @aventus="onAventus"
      @delete="onRemove"
      @invoice="onInvoice"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              <span :class="['completed', 'approved by support'].includes(data.item.state) ? '' : 'text-danger'">{{ data.item.user.firstName }} {{ data.item.user.lastName }}</span>
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              ({{ data.item.user.country.name }})
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
            <b-button
              v-b-tooltip.hover.right="'Show risk score summary for user'"
              size="sm"
              class="m-50"
              variant="outline-warning"
              @click="onRsSummary(data.item)"
            >
              RSS
            </b-button>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
              {{ data.item.account.account_id }} ({{ accountTypes[data.item.account.account_type_name] }} - {{ data.item.account.server.platform.id }})
            </b-link>
            <risk-score-badge
              v-if="data.item.account.risk_score"
              class="mx-1"
              :score-results="data.item.account.risk_score_results"
              :score="data.item.account.risk_score"
            />
            <b-link
              :to="{ name: 'tools-risk-score', params: { id: data.item.account.account_id } }"
              target="_blank"
            >
              <b-button
                v-b-tooltip.hover.top="'Open risk score analysis'"
                class="m-50"
                variant="outline-primary"
                size="sm"
              >
                RS
              </b-button>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.tags"
              :extras-tags="getAccountExtraTags(data.item.account)"
            />
            <span v-if="data.item.account.profit_split === 90">UNFAZED</span>
          </b-col>
        </b-row>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.account.deposit, data.item.account.currency.label) }}
      </template>

      <template #cell(current_balance)="data">
        <span v-if="!data.item.completed">{{ formatCurrency(data.item.account.balance - data.item.account.deposit, data.item.account.currency.label) }}</span>
        <span v-else>Withdrawal completed</span>
      </template>

      <template #cell(captured_balance)="data">
        {{ formatCurrency(data.item.requested_amount, data.item.account.currency.label) }}
      </template>

      <template #cell(amount)="data">
        {{ formatCurrency(profitShareAmount(data.item), data.item.account.currency.label) }}
        <b-badge
          v-if="data.item.account.withdrawal_count === 0 || (data.item.account.withdrawal_count === 1 && data.item.withdrawal_count === 1)"
          variant="light-primary"
        >
          {{ getRootAccount(data.item.account, data.item.requested_amount, data.item.profit_share, data.item.account.currency.label) }}
        </b-badge>
      </template>

      <template #cell(profit_share)="data">
        {{ data.item.profit_share }}%
      </template>

      <template #cell(withdrawal_datetime)="data">
        {{ formatDateTime(data.item.withdrawal_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge
          :variant="`light-${withdrawalStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(confirmed_at)="data">
        {{ formatDateTime(data.item.confirmed_at) }}
      </template>

      <template #cell(stateAction)="data">
        <b-button
          v-if="!data.item.confirmed_at"
          v-b-tooltip.hover.left="'Approve withdrawal to payout'"
          variant="outline-success"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'approved by support')"
        >
          <feather-icon icon="UserCheckIcon" />
        </b-button>
        <b-button
          v-if="!data.item.confirmed_at"
          v-b-tooltip.hover.left="'Change state to INVESTIGATION'"
          variant="outline-warning"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'investigation')"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </template>
    </gql-table>
    <b-modal
      id="withdrawalModal"
      :title="modalTitle"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <withdrawal-form
        :withdrawal="withdrawalEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
    <b-modal
      id="withdrawalCommentModal"
      :title="modalTitle"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <withdrawal-comment-form
        :withdrawal="withdrawalEdit"
        @submit="onEditCommentSubmit"
      />
    </b-modal>
    <b-modal
      id="rsSummaryModal"
      title="Risk score summary"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-linkage-summary-table :advanced-filter="rsSummaryFilter" />
    </b-modal>
    <b-modal
      id="downloadCertificateModal"
      title="Download withdrawal certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        @download="onDownload"
      />
    </b-modal>
    <b-modal
      id="downloadFundedCertificateModal"
      title="Download funded certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        @download="onDownloadFunded"
      />
    </b-modal>
    <b-modal
      id="downloadSocialCertificateModal"
      title="Download social certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        :show-color="false"
        :show-type="false"
        :show-force-generate="false"
        @download="onDownloadSocial"
      />
    </b-modal>
    <b-modal
      id="downloadTotalPayoutCertificateModal"
      title="Download total payout certificate"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <certificate-download-form
        :loading="downloadLoading"
        :show-color="false"
        :show-type="false"
        @download="onDownloadTotalPayout"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge,
  BButton, BButtonGroup, BButtonToolbar, BCard, BCol, BLink, BModal, BRow, VBTooltip,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import AccountLinkageSummaryTable from '@/components/entity/accountLinkageSummary/AccountLinkageSummaryTable.vue'
import WithdrawalForm from '@/views/withdrawal/components/WithdrawalForm.vue'
import WithdrawalCommentForm from '@/views/withdrawal/components/WithdrawalCommentForm.vue'

import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { STATE_VARIANTS } from '@/enums/withdrawals'
import WithdrawalAPI from '@/api/withdrawal'
import AccountAPI from '@/api/account'
import UserAPI from '@/api/user'
import CertificateDownloadForm from '@/components/forms/certificate/CertificateDownloadForm.vue'
import { ACCOUNTS_SHORT } from '@/enums/accounts'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Confirm',
    icon: 'CheckCircleIcon',
    emit: 'confirm',
  },
  {
    text: 'Remove certificate',
    icon: 'TrashIcon',
    emit: 'removeCertificate',
  },
  {
    text: 'Investigation - CONTACTED',
    icon: 'XIcon',
    emit: 'stateInvestigationContacted',
  },
  {
    text: 'Investigation - DECLINED',
    icon: 'XIcon',
    emit: 'stateInvestigationDeclined',
  },
  {
    text: 'Comment',
    icon: 'EditIcon',
    emit: 'editComment',
  },
  {
    text: 'Invoice',
    icon: 'FileIcon',
    emit: 'invoice',
  },
  {
    text: 'Download payout certificate',
    icon: 'DownloadIcon',
    emit: 'downloadCertificate',
  },
  {
    text: 'Download funded certificate',
    icon: 'DownloadIcon',
    emit: 'downloadFundedCertificate',
  },
  {
    text: 'Download social certificate',
    icon: 'DownloadIcon',
    emit: 'downloadSocialCertificate',
  },
  {
    text: 'Download total payout certificate',
    icon: 'DownloadIcon',
    emit: 'downloadTotalPayoutCertificate',
  },
  {
    text: 'AVENTUS',
    icon: 'RepeatIcon',
    emit: 'aventus',
  },
  {
    text: 'Remove withdrawal',
    icon: 'TrashIcon',
    emit: 'delete',
  },
]

export default {
  components: {
    CertificateDownloadForm,
    BCard,
    BButtonToolbar,
    BButtonGroup,
    WithdrawalCommentForm,
    WithdrawalForm,
    BBadge,
    BModal,
    AccountLinkageSummaryTable,
    BRow,
    BButton,
    TagsList,
    BCol,
    RiskScoreBadge,
    BLink,
    GqlTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    showActionBar: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    filterName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rsSummaryFilter: { search: '', user_id: null, country: null },
      withdrawalStateVariants: STATE_VARIANTS,
      accountTypes: ACCOUNTS_SHORT,
      modalTitle: 'Create withdrawal',
      withdrawalEdit: null,
      withdrawalDownloadCertificate: null,
      downloadLoading: false,
      withdrawalCounts: [],
      actions,
      columns1: [
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'multientity', queryType: 'users', value: 'id', label: 'email', search: 'email',
          },
        },
        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'multientity', queryType: 'accounts', value: 'id', label: 'account_id', search: 'account_id',
          },
        },
        {
          name: 'Deposit',
          label: 'Deposit',
          sortable: false,
        },
        {
          name: 'current_balance',
          label: 'Current balance on account',
          sortable: true,
        },
        {
          name: 'captured_balance',
          label: 'Captured balance',
          sortable: true,
        },
        {
          name: 'amount',
          label: 'Profit share $',
          sortable: true,
        },
        {
          name: 'profit_share',
          label: 'Profit share %',
          sortable: true,
        },
        {
          name: 'withdrawal_datetime',
          label: 'Withdrawal time',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          sortable: false,
          filterable: { type: 'multienum', enum: ['requested', 'investigation', 'investigation - contacted', 'investigation - declined', 'approved by support', 'completed'] },
        },
        {
          name: 'platform',
          label: 'Platform',
          sortable: false,
        },
        {
          name: 'confirmed_at',
          label: 'Confirmed at',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'stateAction',
          label: 'State action',
          sortable: false,
        },
        {
          name: 'userTags',
          label: 'User tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'accountTags',
          label: 'Account tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'userCountry',
          label: 'Country',
          filterable: {
            type: 'multientity', queryType: 'countries', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'accountDeposit',
          label: 'Deposit',
          filterable: 'range',
          default: [1, null],
          visible: false,
        },
        {
          name: 'accountType',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
          visible: false,
        },
      ],
      query: [
        'id',
        'amount',
        'comment',
        'completed',
        'confirmed_at',
        'createdAt',
        'profit_share',
        'state',
        'withdrawal_count',
        'requested_amount',
        'createdAt',
        'withdrawal_datetime',
        'platform',
        'comment',
        {
          user: {
            fields: [
              'firstName',
              'lastName',
              'id',
              'email',
              'risk_score',
              'risk_score_results',
              'kyc_at',
              'purchase_disabled',
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
              { country: { fields: ['id', 'name'] } },
            ],
          },
        },
        {
          account: {
            fields: [
              'id',
              'account_id',
              'account_type_name',
              'balance',
              'deposit',
              'extras_tags',
              'risk_score',
              'risk_score_results',
              'phase_move_check',
              'equity_check',
              'withdrawal_count',
              'rule_drawdown_type',
              'rule_first_withdrawal_after',
              'rule_inheritance',
              'rule_max_daily_drawdown',
              'rule_max_days',
              'rule_max_total_drawdown',
              'rule_min_profit',
              'rule_min_trading_days',
              'rule_special',
              'rule_withdrawal_after',
              { currency: { fields: ['label'] } },
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
              { rootParent: { fields: ['id', 'account_id', 'createdAt', { order: { fields: ['id', 'order_number', 'price'] } }] } },
              { server: { fields: ['id', 'name', { platform: { fields: ['id'] } }] } },
            ],
          },
        },
      ],
    }
  },
  mounted() {
    if (this.showActionBar) {
      WithdrawalAPI.withdrawalsCounts().then(response => {
        this.withdrawalCounts = response
      })
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    profitShareAmount(item) {
      return item.requested_amount !== null ? (item.requested_amount * (item.profit_share / 100)) : (item.amount * (item.profit_share / 100))
    },
    amount(item) {
      if (item.amount === 0) return item.account.balance - item.account.deposit

      return item.amount
    },
    onRsSummary(item) {
      this.rsSummaryFilter.user_id = item.user.id
      this.rsSummaryFilter.country = item.user.country.id
      this.$bvModal.show('rsSummaryModal')
    },
    getRootAccount(account, amount, profitSplit, currency) {
      if (account.rootParent !== null) {
        const createdAt = new Date(account.rootParent.createdAt)
        const dateToRefundableFee = new Date('2024-02-16T19:00:00+00:00')
        const dateTo5percBonus = new Date('2024-07-30T15:00:00+00:00')
        if (createdAt > dateTo5percBonus) return 'No refundable fee or bonus.'
        if (createdAt < dateToRefundableFee) return account.rootParent.order === null ? '+ Original order fee not found' : `+ order fee ${formatCurrency(account.rootParent.order.price, 'USD')}`

        return `+ 5% profit split. Total ${formatCurrency(amount * ((profitSplit + 5) / 100), currency)}`
      }
      return '+ Original order fee not found'
    },
    onChangeState(item, state) {
      WithdrawalAPI.changeWithdrawalState(item.id, state).then(() => {
        this.$refs.withdrawalTable.reloadAll(true)
      })
    },
    onInvestigationContacted(item) {
      WithdrawalAPI.changeWithdrawalState(item.id, 'investigation - contacted').then(() => {
        this.$refs.withdrawalTable.reloadAll(true)
      })
    },
    onInvestigationDeclined(item) {
      WithdrawalAPI.changeWithdrawalState(item.id, 'investigation - declined').then(() => {
        this.$refs.withdrawalTable.reloadAll(true)
      })
    },
    onAventus(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account.account_id} will be checked by AVENTUS`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, run analysis',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.requestAventus(item.account.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account was add to the front successfully',
                icon: 'WarningIcon',
                text: 'Account is waiting for test now!',
                variant: 'success',
              },
            })
            this.$refs.withdrawalTable.reloadAll(true)
          })
        }
      })
    },
    onEdit(item) {
      this.modalTitle = 'Edit withdrawal'
      this.withdrawalEdit = item
      this.$bvModal.show('withdrawalModal')
    },
    onEditComment(item) {
      this.modalTitle = 'Edit Comment'
      this.withdrawalEdit = item
      this.$bvModal.show('withdrawalCommentModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('withdrawalModal')
      this.withdrawalEdit = null
      this.$refs.withdrawalTable.reloadAll(true)
    },
    onEditCommentSubmit() {
      this.$bvModal.hide('withdrawalCommentModal')
      this.withdrawalEdit = null
      this.$refs.withdrawalTable.reloadAll(true)
    },
    onCreateWithdrawal() {
      this.modalTitle = 'Create withdrawal'
      this.withdrawalEdit = null
      this.$bvModal.show('withdrawalModal')
    },
    onConfirm(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal will be approved!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.confirmWithdrawal(item.id).then(() => {
            this.$refs.withdrawalTable.reloadAll(true)
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Filed',
                icon: 'XCircleIcon',
                text: err.data.detail,
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    onRemoveCertificate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal certificate will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.removeWithdrawalCertificate(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Certificate was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    onRemove(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.removeWithdrawal(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Withdrawal was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
            this.$refs.withdrawalTable.reloadAll(true)
          })
        }
      })
    },
    onInvoice(item) {
      WithdrawalAPI.getWithdrawalInvoice(item.id, item.user.email)
    },
    onDownloadCertificate(item) {
      this.withdrawalDownloadCertificate = item
      this.$bvModal.show('downloadCertificateModal')
    },
    onDownloadFundedCertificate(item) {
      this.withdrawalDownloadCertificate = item
      this.$bvModal.show('downloadFundedCertificateModal')
    },
    onDownloadSocialCertificate(item) {
      this.withdrawalDownloadCertificate = item
      this.$bvModal.show('downloadSocialCertificateModal')
    },
    onDownloadTotalPayoutCertificate(item) {
      this.withdrawalDownloadCertificate = item
      this.$bvModal.show('downloadTotalPayoutCertificateModal')
    },
    onDownload(form) {
      this.downloadLoading = true

      WithdrawalAPI.getCertificate(this.withdrawalDownloadCertificate.account.account_id, this.withdrawalDownloadCertificate.id, form.color, form.type, form.size, form.forceGenerate)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.withdrawalDownloadCertificate = null
          this.downloadLoading = false
          this.$bvModal.hide('downloadCertificateModal')
        })
    },
    onDownloadFunded(form) {
      this.downloadLoading = true

      AccountAPI.getCertificate(this.withdrawalDownloadCertificate.account.account_id, form.color, form.type, form.size, form.forceGenerate)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.withdrawalDownloadCertificate = null
          this.downloadLoading = false
          this.$bvModal.hide('downloadFundedCertificateModal')
        })
    },
    onDownloadSocial(form) {
      this.downloadLoading = true

      UserAPI.getCertificate(this.withdrawalDownloadCertificate.user.email, this.withdrawalDownloadCertificate.user.id, form.size, 'social')
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.withdrawalDownloadCertificate = null
          this.downloadLoading = false
          this.$bvModal.hide('downloadSocialCertificateModal')
        })
    },
    onDownloadTotalPayout(form) {
      this.downloadLoading = true

      UserAPI.getCertificate(this.withdrawalDownloadCertificate.user.email, this.withdrawalDownloadCertificate.user.id, form.size, 'total', true)
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'TrashIcon',
              text: error.status === 400 ? 'Certificate is not generated yet' : error.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.withdrawalDownloadCertificate = null
          this.downloadLoading = false
          this.$bvModal.hide('downloadTotalPayoutCertificateModal')
        })
    },
  },
}
</script>
