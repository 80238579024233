import axiosInstance from '@/plugins/axios'

export default {

  confirmWithdrawal(id, data) {
    const url = `admin/withdrawals/${id}/confirm`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  changeWithdrawalState(id, state) {
    const url = `admin/withdrawals/${id}/state`

    return axiosInstance.post(url, { state })
      .then(response => response.data)
  },

  removeWithdrawalCertificate(id) {
    const url = `admin/withdrawals/${id}/remove-files`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  withdrawalsCounts() {
    const url = 'admin/withdrawals-summary'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  removeWithdrawal(id) {
    const url = `admin/withdrawals/${id}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  getWithdrawalInvoice(id, email) {
    const url = `admin/withdrawals/${id}/invoice`

    return axiosInstance.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${email}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  },

  getCertificate(accountId, withdrawalId, color = 'dark', type = 'a4', size = 'small', forceGenerate = false) {
    const url = `admin/withdrawals/${withdrawalId}/certificate`

    return axiosInstance.get(url, {
      responseType: 'blob',
      params: {
        size, color, type, forceGenerate,
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Certificate of payout - ${accountId}.${size === 'print' ? 'pdf' : 'jpg'}`)
      document.body.appendChild(link)
      link.click()
    })
  },

}
