import axiosInstance from '@/plugins/axios'

export default {
  getCountries() {
    const url = 'common/countries'

    return axiosInstance.get(url)
      .then(response => response.data)
  },
  getLanguages() {
    const url = 'common/languages'

    return axiosInstance.get(url)
      .then(response => response.data)
  },
}
