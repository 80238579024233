<template>
  <v-select
    :options="options"
    :value="value"
    label="text"
    :multiple="multiple"
    :placeholder="placeholder"
    :reduce="item => item.value"
    @input="updateValue"
  />
</template>

<script>
import vSelect from 'vue-select'
import AppAPI from '@/api/app'

export default {
  components: {
    vSelect,
  },
  props: {
    value: [String, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placeholder: 'Loading...',
      selected: this.value,
      options: [],
    }
  },
  beforeMount() {
    AppAPI.getCountries().then(data => {
      this.options = Object.keys(data).map(k => ({
        value: k,
        text: data[k],
      }))
      this.placeholder = 'Select country'
    })
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
