const STATE_VARIANTS = {
  requested: 'primary',
  investigation: 'danger',
  'investigation - contacted': 'danger',
  'investigation - declined': 'danger',
  'approved by support': 'warning',
  completed: 'success',
}

export { STATE_VARIANTS }
