const CERTIFICATE_SIZES = [
  'large',
  'small',
  'print',
]

const CERTIFICATE_TYPES = [
  'square',
  'a4',
]

const CERTIFICATE_COLOR = [
  'dark',
  'light',
]

export {
  CERTIFICATE_SIZES, CERTIFICATE_TYPES, CERTIFICATE_COLOR,
}
