<template>
  <entity-form
    :id="withdrawal ? withdrawal.id : null"
    module-name="withdrawalsList"
    :model="form"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <b-form-group class="mt-1">
      <label>Comment</label>
      <b-form-textarea
        v-model="form.comment"
        placeholder="Comment"
        rows="8"
      />
    </b-form-group>
  </entity-form>
</template>
<script>
import {
  BFormTextarea, BFormGroup,
} from 'bootstrap-vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    EntityForm,
  },
  props: {
    title: String,
    withdrawal: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        comment: this.withdrawal ? this.withdrawal.comment : '',
      },
    }
  },
  methods: {
    beforeSubmit() {
      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
