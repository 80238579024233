<template>
  <div>
    <gql-table
      ref="linkageGqlTable"
      :fields="columns"
      :query="query"
      query-type="linkages"
      sort-by="createdAt"
      :sort-desc="true"
    >
      <template #cell(searchUser)="data">
        <b-link
          :to="{ name: 'user-detail', params: { id: data.item.account.user.id } }"
        >
          {{ data.item.account.user.email }} ({{ data.item.account.user.country.name }})
        </b-link>
      </template>

      <template #cell(account)="data">
        <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
          {{ data.item.account.account_id }}
        </b-link>
        <b-button
          class="m-1"
          variant="outline-primary"
          @click="onClickRiskScore(data.item.account.account_id)"
        >
          Risk score
        </b-button>
      </template>

      <template #cell(targetUser)="data">
        <b-link
          :to="{ name: 'user-detail', params: { id: data.item.linkedAccount.user.id } }"
        >
          {{ data.item.linkedAccount.user.email }} ({{ data.item.linkedAccount.user.country.name }})
        </b-link>
      </template>

      <template #cell(linked_account)="data">
        <b-link :to="{ name: 'account-detail', params: { id: data.item.linkedAccount.account_id } }">
          {{ data.item.linkedAccount.account_id }}
        </b-link>
        <b-button
          class="m-1"
          variant="outline-primary"
          @click="onClickRiskScore(data.item.linkedAccount.account_id)"
        >
          Risk score
        </b-button>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>
    </gql-table>
  </div>
</template>
<script>
import { BButton, BLink } from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { formatDateTime } from '@/plugins/formaters'

const query = [
  'id',
  'createdAt',
  'updatedAt',
  {
    account: {
      fields: [
        'id',
        'account_id',
        { user: { fields: ['id', 'firstName', 'lastName', 'email', { country: { fields: ['id', 'name'] } }] } },
      ],
    },
  },
  {
    linkedAccount: {
      fields: [
        'id',
        'account_id',
        { user: { fields: ['id', 'firstName', 'lastName', 'email', { country: { fields: ['id', 'name'] } }] } },
      ],
    },
  },
]

export default {
  components: { BButton, BLink, GqlTable },
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      query,
    }
  },
  methods: {
    formatDateTime,
    onClickRiskScore(accountId) {
      const route = this.$router.resolve({ name: 'tools-risk-score', params: { id: accountId } })
      window.open(route.href)
    },
  },
}
</script>
