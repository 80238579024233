<template>
  <b-overlay :show="loading">
    <b-form-group
      v-if="showType"
      label="Type"
    >
      <b-form-select
        v-model="form.type"
        :options="types"
      />
    </b-form-group>
    <b-form-group
      v-if="showSize"
      label="Size"
    >
      <b-form-select
        v-model="form.size"
        :options="sizes"
      />
    </b-form-group>
    <b-form-group
      v-if="showColor"
      label="Color"
    >
      <b-form-select
        v-model="form.color"
        :options="colors"
      />
    </b-form-group>
    <b-form-group
      v-if="showForceGenerate"
    >
      <b-form-checkbox v-model="form.forceGenerate">
        Force generate
      </b-form-checkbox>
    </b-form-group>
    <hr>
    <b-button
      class="float-right"
      variant="outline-success"
      @click="onDownload"
    >
      Download
    </b-button>
  </b-overlay>
</template>
<script>
import {
  BFormSelect, BFormGroup, BButton, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import { CERTIFICATE_TYPES, CERTIFICATE_COLOR, CERTIFICATE_SIZES } from '@/enums/certificates'

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BButton,
    BOverlay,
    BFormCheckbox,
  },
  props: {
    loading: Boolean,
    showType: {
      type: Boolean,
      default: true,
    },
    showSize: {
      type: Boolean,
      default: true,
    },
    showColor: {
      type: Boolean,
      default: true,
    },
    showForceGenerate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      types: CERTIFICATE_TYPES,
      colors: CERTIFICATE_COLOR,
      sizes: CERTIFICATE_SIZES,
      form: {
        color: CERTIFICATE_COLOR[0],
        type: CERTIFICATE_TYPES[0],
        size: CERTIFICATE_SIZES[0],
        forceGenerate: false,
      },
    }
  },
  methods: {
    onDownload() {
      this.$emit('download', this.form)
    },
  },
}
</script>
